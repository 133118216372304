<template>
    <section>
        <section class="mt-20" v-loading="loading">
            <div class="panel-search-toolbar mt-20">
                <el-form inline ref="form">
                    <el-form-item label="Search">
                        <el-input style="width: 240px;" v-model="filter.keyword" class="custom-input"
                                  @keyup.enter.native="getList"
                                  placeholder="Please input the keyword..."
                                  autocomplete="off"></el-input>
                        <el-button icon="el-icon-search" type="primary" @click="getList">Search</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <el-table :data="data" empty-text="No data" size="medium" stripe class="custom-table" style="width: auto;">
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <register :value="props.row" otype="readonly" :keyword="filter.keyword.trim()"></register>
                    </template>
                </el-table-column>
                <el-table-column type="index" label="#" width="50"></el-table-column>
                <el-table-column prop="department" label="Department" width="150"></el-table-column>
                <el-table-column prop="username" label="Code" width="150">
                    <template slot-scope="scope">
                        <span @click="handleCopy(scope.row.username,$event)"
                              v-html="$brightenKeyword(scope.row.username, filter.keyword)"></span>
                    </template>
                </el-table-column>
                <el-table-column prop="education" label="Education" width="150"></el-table-column>
                <!--                <el-table-column prop="contact" label="Tel" width="180"></el-table-column>-->
                <el-table-column width="150">
                    <template slot="header" slot-scope="scope">
                        China Zone<br> (BJ Time 8-20)
                    </template>
                    <template slot-scope="scope">
                        <el-button type="success" size="mini" icon="el-icon-chat-round" @click="getQrcode(scope.row,'cn')">
                            WeCom Qrcode
                        </el-button>
                        <!--                        <el-button type="primary" size="mini" icon="el-icon-message" @click="sendEmail(scope.row)">-->
                        <!--                            Email Contact-->
                        <!--                        </el-button>-->
                        <!--                        <el-button type="success" size="mini" icon="el-icon-chat-line-square"-->
                        <!--                                   @click="showWecomDialog(scope.row)">-->
                        <!--                            WeCom Contact-->
                        <!--                        </el-button>-->
                    </template>
                </el-table-column>
                <el-table-column width="150">
                    <template slot="header" slot-scope="scope">
                        EUR&US Zone<br> (US Time 8-20)
                    </template>
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" icon="el-icon-chat-round" @click="getQrcode(scope.row,'us')">
                            WeCom Qrcode
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column min-width="150">
                    <template slot-scope="scope">
                        <el-button type="warning" size="mini" icon="el-icon-user" @click="handleContact(scope.row)">
                            Contact
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="panel-pagination el-row">
                <el-col :span="24">
                    <!--                    layout="prev, pager, next"-->
                    <el-pagination
                            background
                            layout="total, sizes, prev, pager, next"
                            :page-sizes="[10, 20, 50, 100, 200]"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :page-size="filter.pageSize"
                            :total="totalRows">
                    </el-pagination>
                </el-col>
            </div>
            <br>
            <br>
        </section>
        <el-dialog :visible.sync="dialogVisible"
                   width="300px" top="25vh"
                   :before-close="handleCloseWecomDialog">
            <span slot="title">
                <img src="../../assets/img/wecom-logo-2.png" width="" height="28" style="vertical-align: middle;">
            </span>
            <div class="text-center" style="margin-top: -20px;">
<!--                <p style="color:#3582e7;padding-bottom: 10px;">{{wecomQrcodeDep}} 负责人 [{{wecomQrcodeDepIndex+1}}]</p>-->
                <div v-loading="wecomQrcodeLoading" class="text-center"
                     style="padding:15px; border:1px solid #ddd; width:230px;height:230px;">
<!--                    <template v-if="wecomQrcodeSrc.length==1">-->
                        <img v-if="wecomQrcodeZone=='cn' & wecomQrcodeSrc_CN.length>0" :src="wecomQrcodeSrc_CN[0]" width="230" height="230" style="width:230px;height:230px;">
                        <img v-if="wecomQrcodeZone=='us' & wecomQrcodeSrc_US.length>0" :src="wecomQrcodeSrc_US[0]" width="230" height="230" style="width:230px;height:230px;">
<!--                    </template>-->
<!--                    <template v-else>-->
<!--                        <el-carousel trigger="click" height="230px" :interval="10000"-->
<!--                                     indicator-position="none"-->
<!--                                     @change="changeQrcodeDepTitle">-->
<!--                            <el-carousel-item v-for="item in 1" :key="item">-->
<!--                                <img v-if="wecomQrcodeSrc" :src="wecomQrcodeSrc" width="230" height="230"-->
<!--                                     style="width:230px;height:230px;">-->
<!--                            </el-carousel-item>-->
<!--                        </el-carousel>-->
<!--                    </template>-->
                </div>
            </div>
        </el-dialog>
        <!--        <el-dialog title="WeCom Contact &#45;&#45; Description"-->
        <!--                   :visible.sync="dialogVisible"-->
        <!--                   width="50%"-->
        <!--                   :before-close="handleCloseWecomDialog">-->
        <!--            <el-input type="textarea"-->
        <!--                      placeholder="Please enter the description"-->
        <!--                      :autosize="{ minRows: 4, maxRows: 8}"-->
        <!--                      v-model="wecomForm.description"></el-input>-->
        <!--            <span slot="footer" class="dialog-footer">-->
        <!--                <el-button @click="dialogVisible = false">Cancel</el-button>-->
        <!--                <el-button type="primary" @click="sendWecom">Contact</el-button>-->
        <!--            </span>-->
        <!--        </el-dialog>-->
        <el-dialog title="Confirm to Notify the Department Head"
                   :visible.sync="wcDialogVisible" width="50%" class="custom-dialog"
                   :before-close="handleCloseWcDialog">
            <p>You are now contacting {{wcForm.department}} {{wcForm.username}}</p>
            <br>
            <p>Do you confirm to notify the relevant department head of this expert? The system will automatically push your contact request to the All-in-One Wework group chat for timely notification.</p>
            <span slot="footer" class="dialog-footer text-center">
                <el-button @click="handleCloseWcDialog">Cancel</el-button>
                <el-button type="primary" :loading="wcLoading" @click="robotMessage">Confirm and Notify</el-button>
            </span>
        </el-dialog>
    </section>
</template>

<script>

    import copyText from '@/utils/clipboard'
    import Register from "./Register";

    export default {
        name: "Search",
        components: {Register},
        data() {
            return {
                userInfo: {},
                data: [],
                filter: {
                    keyword: "",
                    isSelect: false,
                    page: 1,
                    pageSize: 10,
                },
                // multipleSelection:[],
                // multipleSelectionIds:[],
                loading: false,
                totalPage: 0,
                totalRows: 0,
                editForm: {},
                editVisible: false,
                dialogVisible: false,
                // wecomQrcodeDep: '',
                // wecomQrcodeDepIndex: 0,
                wecomQrcodeZone: '',
                wecomQrcodeSrc_CN: [],
                wecomQrcodeSrc_US: [],
                wecomQrcodeLoading: '',
                // wecomForm: {
                //     id:'',
                //     description:'',
                //     search_keyword:'',
                // }
                //robot
                wcDialogVisible:false,
                wcForm:{},
                wcLoading:false
            };
        },
        methods: {
            handleBack() {
                this.editForm = {};
                this.editVisible = false;
                this.getList();
            },
            handleCopy(text, event) {
                copyText(text, event);
            },
            handleSizeChange(val) {
                this.filter.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.filter.page = val;
                this.getList();
            },
            sendEmail(row) {
                var params = {
                    "command": "/sendeamil/email",
                    "data": {id: row.id}
                };
                this.$WebApi(params).then(res => {
                    if (res.code != 200) return;
                    this.$message({type: 'success', message: 'Send success'});
                });
            },
            getQrcode(row,zone) {
                // this.wecomQrcodeDepIndex = 0;
                this.wecomQrcodeZone = zone;
                this.wecomQrcodeSrc_CN = [];
                this.wecomQrcodeSrc_US = [];
                // this.dialogVisible = true;
                // return;
                // this.wecomQrcodeDep = row.department;
                var params = {
                    "command": "/Qrcode/img",
                    "data": {id: row.id}
                };
                this.wecomQrcodeLoading = true;
                this.$WebApi(params).then(res => {
                    this.wecomQrcodeLoading = false;
                    if (res.code != 200) return;
                    this.wecomQrcodeSrc_CN = res.data.cn;
                    this.wecomQrcodeSrc_US = res.data.us;
                    // console.log('CN: '+this.wecomQrcodeSrc_CN[0]);
                    // console.log('US: '+this.wecomQrcodeSrc_US[0]);
                    if(res.data.us.length<=0 & zone == 'us'){
                        this.$message({type: 'warning', message: 'No EUR&US Zone Data'});
                    }
                    else if(res.data.cn.length<=0 & zone == 'cn'){
                        this.$message({type: 'warning', message: 'No China Zone Data'});
                    }
                    else{
                        this.dialogVisible = true;
                    }
                });
            },
            handleCloseWecomDialog() {
                this.dialogVisible = false;
                // this.wecomQrcodeDep = '';
                // this.wecomQrcodeDepIndex = 0;
                this.wecomQrcodeSrc_CN = [];
                this.wecomQrcodeSrc_US = [];
                // this.wecomForm = {
                //     id : '',
                //     search_keyword : '',
                //     description : '',
                // }
            },
            // changeQrcodeDepTitle(index) {
            //     console.log(index);
            //     this.wecomQrcodeDepIndex = index;
            // },
            // showWecomDialog(row) {
            //     this.dialogVisible = true;
            //     this.wecomDescription = '';
            //     this.wecomForm = {
            //         id : row.id,
            //         search_keyword : this.filter.keyword,
            //         description : ''
            //     }
            // },
            // sendWecom() {
            //     var params = {
            //         "command": "/send_we_com/send",
            //         "data": this.wecomForm
            //     };
            //     // console.log(params)
            //     // return;
            //     this.$WebApi(params).then(res => {
            //         if (res.code != 200) return;
            //         this.handleCloseWecomDialog();
            //         this.$message({type: 'success', message: 'Send success'});
            //     });
            // },
            getList() {
                this.filter.keyword = this.filter.keyword.trim();
                if (this.filter.keyword == '') {
                    this.$message({type: 'warning', message: 'Please input the keyword!'});
                    return;
                }
                this.filter.isSelect = this.filter.keyword == '' ? false : true;
                var params = {
                    "command": "/talents_index/index",
                    "data": this.filter
                };
                this.data = [];
                this.loading = true;
                this.$WebApi(params).then(res => {
                    this.loading = false;
                    if (res.code != 200) {
                        return;
                    }
                    this.data = res.data;
                    this.totalPage = res.totalPage;
                    this.totalRows = res.totalRows;
                });
            },
            handleContact(row){
                this.wcDialogVisible = true;
                this.wcForm = row;
            },
            handleCloseWcDialog(){
                this.wcDialogVisible = false;
                this.wcForm = {};
            },
            robotMessage(){
                var params = {
                    "command": "/send_we_com/robotMessage",
                    "data": {id: this.wcForm.id}
                };
                this.wcLoading = true;
                this.$WebApi(params).then(res => {
                    this.wcLoading = false;
                    if (res.code != 200) {
                        return;
                    }
                    this.$message({type: 'success', message: 'Submitted successfully! The relevant person in charge has been notified to handle it.'});
                    this.handleCloseWcDialog();
                });
            }

        },
        mounted() {
            this.userInfo = JSON.parse(sessionStorage.getItem('user'));
            let k = this.$route.query.keyword;
            if (k) this.filter.keyword = k;
            this.getList();
        }
    }
</script>

<style scoped>

    .custom-input {
        height: 40px;
        border-radius: 0
    }

    .custom-input >>> .el-input__inner {
        height: 40px;
        border-radius: 0;
        border-color: transparent
    }

    .custom-input >>> .el-input__inner:focus {
        border-color: #097ff5
    }

    .custom-table >>> .el-table__expanded-cell {
        padding-top: 0;
        padding-right: 0;
    }
    .custom-dialog >>> .el-dialog__body{
        word-break: break-word;
        font-size: 15px;
        line-height: 1.5;
    }
    .custom-dialog >>> .el-dialog__footer{
        text-align: center;
    }
</style>
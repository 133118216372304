<template>
    <section class="mt-20" v-loading="loading">
        <el-table :data="data" empty-text="No data" size="medium" stripe>
            <el-table-column type="index" label="#" width="70"></el-table-column>
            <el-table-column prop="name" label="Name" width="150"></el-table-column>
            <el-table-column prop="qrcode" label="中国联系人" width="300">
                <template slot-scope="scope">
                    <img :src="scope.row.qrcode" width="140">
                </template>
            </el-table-column>
            <el-table-column prop="qrcode_us" label="美国联系人">
                <template slot-scope="scope">
                    <img :src="scope.row.qrcode_us" width="140">
                </template>
            </el-table-column>
        </el-table>
    </section>
</template>

<script>

    export default {
        name: "DepartmentQrcodeList",
        data() {
            return {
                data: [],
                filter: {
                    page: 1,
                    pageSize: 100,
                },
                loading: false,
                totalLogin: 0,
                totalPage: 0,
                totalRows: 0,
            };
        },
        methods: {
            handleSizeChange(val) {
                this.filter.pageSize = val;
                this.getlist();
            },
            handleCurrentChange(val) {
                this.filter.page = val;
                this.getlist();
            },
            getlist() {
                var loginParams = {
                    "command": "/talents_department/getDepartmentQrcodeList",
                    "data": this.filter
                };
                this.loading = true;
                this.$WebApi(loginParams).then(res => {
                    this.loading = false;
                    if (res.code != 200) {
                        return
                    }
                    this.data = res.data;
                    this.totalPage = res.totalPage;
                    this.totalRows = res.totalRows;
                });
            },
        },
        mounted() {
            this.getlist();
        }
    }
</script>

<style scoped>
    /*.el-picker-panel [slot=sidebar], .custom-popper .el-picker-panel__sidebar{*/
    /*    width: 145px;*/
    /*}*/
    /*.custom-popper >>> .el-picker-panel__body{*/
    /*    margin-left: 145px;*/
    /*}*/
    .el-form--inline .el-form-item {
        margin-bottom: 10px;
    }


</style>

